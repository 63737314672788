import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  VERTICAL_NAVIGATION,
} from "constants/ActionTypes";
import { userSignOut } from "actions/Auth";
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import UserInfoHorizontal from "components/UserInfoHorizontal";
import { auth, firestore } from "../../firebase/firebase";
import {
  validateForms,
  setDateFormat,
  getLocalStorage,
  setLocalStorage,
} from "actions/Helpers";

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification,
    });
  };

  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo,
    });
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      modo_edicion_dashboard: false,
      modo_edicion_respuestas: false,
      open: false,
      open_confirm_delete: false,
      dashboards: [],
      respuestas_con_alertas: [],
      id: "",
      uid: "",
      email: "",
      displayName: "",
      nombre: "",
      descripcion: "",
      width: 0,
      rules: [
        {
          field: "nombre",
          label: "Nombre",
          type: "text",
          required: true,
        },
        {
          field: "descripcion",
          label: "Descripción",
          type: "text",
          required: true,
        },
      ],
      errors: {
        nombre: "",
        descripcion: "",
      },
    };

    window.addEventListener("resize", this.update);
  }

  componentDidMount() {
    this.update();
    return auth.onAuthStateChanged(async (user) => {
      if (user) {
        const id_marca = getLocalStorage("id_marca");
        await this.obtenerRespuestasConAlerta(user.uid, user.email, id_marca);
        return firestore
          .collection("usuarios")
          .doc(user.uid)
          .onSnapshot(
            async (doc) => {
              if (doc.exists) {
                await this.obtenerDashboards(user.uid);
                return this.setState({
                  uid: user.uid,
                  email: user.email,
                  displayName: user.displayName,
                  modo_edicion_dashboard: doc.data().modo_edicion_dashboard
                    ? doc.data().modo_edicion_dashboard
                    : false,
                  modo_edicion_respuestas: doc.data().modo_edicion_respuestas
                    ? doc.data().modo_edicion_respuestas
                    : false,
                });
              } else {
                return this.setState({
                  uid: user.uid,
                  email: user.email,
                  displayName: user.displayName,
                  modo_edicion_dashboard: false,
                  modo_edicion_respuestas: false,
                });
              }
            },
            (error) => {
              //console.log(error);
              return false;
            }
          );
      } else {
        this.props.userSignOut();
      }
    });
  }

  obtenerRespuestasConAlerta = async (uid, email, id_marca) => {
    const rango_fechas = [
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      new Date(),
    ];
    firestore
      .collection("notificaciones")
      .where("correo", "==", email)
      .where("estado", "==", "Enviada")
      .where("id_marca", "==", id_marca)
      .where("fecha_creacion", ">", rango_fechas[0])
      .where("fecha_creacion", "<", rango_fechas[1])
      .orderBy("fecha_creacion", "desc")
      .limit(10)
      .onSnapshot(
        async (snapShots) => {
          //onst mails_sin_formato = snapShots.docs;
          const respuestas_con_alertas = [];
          await this.setState({
            respuestas_con_alertas,
          });
          return true;
        },
        (error) => {
          //console.log(error);
        }
      );
  };

  update = () => {
    this.setState({
      //height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  resetForm() {
    this.refs.tablerosForm.reset();
    this.setState({
      nombre: "",
      descripcion: "",
      open: false,
      errors: {
        nombre: "",
        descripcion: "",
      },
    });
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  showMenuIcon(data) {
    if (data.navigationStyle === HORIZONTAL_NAVIGATION) {
      return (
        <div
          className="d-block d-md-none pointer mr-3"
          onClick={this.onToggleCollapsedNav}
        >
          <span className="jr-menu-icon">
            <span className="menu-icon" />
          </span>
        </div>
      );
    } else {
      if (data.path !== "dashboard") {
        return (
          <IconButton
            className={`jr-menu-icon mr-3 ${data.drawerStyle}`}
            aria-label="Menu"
          >
            <IconButton
              className={`jr-menu-icon mr-3 ${data.drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon" />
            </IconButton>
          </IconButton>
        );
      } else {
        return (
          <Link className="app-logo mr-2 d-sm-block" to="/">
            <img
              className="app_logo_fixed"
              src={require("assets/images/logo.png")}
              alt="Jambo"
              title="Jambo"
            />
          </Link>
        );
      }
    }
  }

  updateInput = async (e) => {
    const { uid } = this.state;
    if (e.target.value === "nuevo") {
      return this.setState({
        open: true,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
      await this.activarDashboard(uid, e.target.value);
      return await this.inactivarDashboards(uid, e.target.value);
    }
  };

  updateInputTableros = (e) => {
    return this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activarDashboard = (uid, id) => {
    setLocalStorage("id_dashboard", id);
    return firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .doc(id)
      .update({
        seleccionado: true,
      })
      .then(() => {
        console.log("Tablero seleccionado con éxito");
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  activarEdicion = () => {
    return auth.onAuthStateChanged(async (user) => {
      return firestore
        .collection("usuarios")
        .doc(user.uid)
        .update({
          modo_edicion_dashboard: this.state.modo_edicion_dashboard
            ? false
            : true,
        })
        .then(async () => {
          this.state.modo_edicion_dashboard === false &&
            (await this.actualizarDashboard(user.uid));
          return this.setState({
            modo_edicion_dashboard: !this.state.modo_edicion_dashboard
              ? false
              : true,
          });
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    });
  };

  activarEdicionRespuestas = () => {
    let filtros_respuestas = JSON.parse(getLocalStorage("filtros_respuestas"));
    let rango_fechas_respuestas = JSON.parse(
      getLocalStorage("rango_fechas_respuestas")
    );
    return auth.onAuthStateChanged(async (user) => {
      return firestore
        .collection("usuarios")
        .doc(user.uid)
        .update({
          modo_edicion_respuestas: this.state.modo_edicion_respuestas
            ? false
            : true,
          respuestas: {
            filtros: this.obtenerFiltrosAntesDeGuardar(filtros_respuestas),
            rango_fechas: rango_fechas_respuestas.map(
              (fecha) => new Date(fecha)
            ),
          },
        })
        .then(() => {
          return this.setState({
            modo_edicion_respuestas: !this.state.modo_edicion_respuestas
              ? false
              : true,
          });
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    });
  };

  obtenerDashboards = (uid) => {
    return firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .where("estado_eliminado", "==", false)
      .onSnapshot(
        (snapShot) => {
          const dashboards = snapShot.docs.map((item) => {
            return {
              id: item.id,
              ...item.data(),
            };
          });
          const dashboard_seleccionado = this.obtenerDashboardSeleccionado(
            dashboards
          );
          dashboards.push({ id: "nuevo", nombre: "Crear nuevo" });
          return this.setState({
            dashboards: dashboards,
            id: dashboard_seleccionado.id ? dashboard_seleccionado.id : "",
          });
        },
        (error) => {
          //console.log(error);
          return false;
        }
      );
  };

  obtenerDashboardSeleccionado = (items) => {
    const buscar_seleccionado = items.filter(
      (item) => item.seleccionado === true
    );
    return buscar_seleccionado.length > 0 ? buscar_seleccionado[0] : {};
  };

  obtenerFiltrosAntesDeGuardar = (filtros) => {
    let filtros_para_guardar = [];
    filtros.forEach((item) => {
      let buscar_filtro = filtros_para_guardar.filter(
        (filtro) => filtro.tipo === item.tipo
      );
      buscar_filtro.length === 0 &&
        item.tipo &&
        filtros_para_guardar.push({
          tipo: item.tipo,
          operador: item.operador,
          valores: item.valores,
          //valores: item.valores
        });
    });
    return filtros_para_guardar;
  };

  actualizarDashboard = (uid) => {
    let id_dashboard = getLocalStorage("id_dashboard");
    let widgets = JSON.parse(getLocalStorage("widgets"));
    let filtros = JSON.parse(getLocalStorage("filtros"));
    let rango_fechas = JSON.parse(getLocalStorage("rango_fechas"));

    firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .doc(id_dashboard)
      .update({
        widgets: widgets.map((widget, index) => {
          return {
            id: widget.id,
            posicion: index,
          };
        }),
        filtros: this.obtenerFiltrosAntesDeGuardar(filtros),
        rango_fechas: rango_fechas.map((fecha) => new Date(fecha)),
      })
      .then(() => {
        //console.log(`Posiciones de los widgets en el dashboard ${id_dashboard} actualizadas con éxito`);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  actualizarRespuestas = (uid) => {
    let filtros_respuestas = JSON.parse(getLocalStorage("filtros_respuestas"));
    let rango_fechas_respuestas = JSON.parse(
      getLocalStorage("rango_fechas_respuestas")
    );

    firestore
      .collection("usuarios")
      .doc(uid)
      .update({
        respuestas: {
          filtros: this.obtenerFiltrosAntesDeGuardar(filtros_respuestas),
          rango_fechas: rango_fechas_respuestas.map((fecha) => new Date(fecha)),
        },
      })
      .then(() => {
        //console.log(`Posiciones de los widgets en el dashboard ${id_dashboard} actualizadas con éxito`);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  validateForm = () => {
    const results = validateForms(this.state);
    this.setState({
      errors: results[0],
    });
    return results[1];
  };

  actionDocumento = (e) => {
    e.preventDefault();
    const { nombre, descripcion, uid, displayName, email } = this.state;
    return this.validateForm()
      ? firestore
          .collection("usuarios")
          .doc(uid)
          .collection("dashboards")
          .add({
            nombre: nombre,
            descripcion: descripcion,
            seleccionado: true,
            estado_registro: true,
            estado_eliminado: false,
            creado_por: email,
            fecha_creacion: setDateFormat(new Date()),
            modificado_por: null,
            fecha_eliminacion: null,
            fecha_modificacion: null,
            eliminado_por: null,
            nombre_creadoPor: displayName,
            nombre_eliminadoPor: null,
            nombre_modificadoPor: null,
            widgets: [],
            filtros: [],
            rango_fechas: [
              new Date(new Date().setDate(new Date().getDate() - 90)),
              new Date(),
            ],
          })
          .then(async (docRef) => {
            console.log("Dashboard creado con éxito");
            await this.inactivarDashboards(uid, docRef.id);
            return this.handleRequestClose();
          })
          .catch((error) => {
            console.log(error);
            return false;
          })
      : false;
  };

  handleRequestClose = () => {
    return this.setState({ open: false });
  };

  handleRequestCloseDeleteConfirm = () => {
    return this.setState({ open_confirm_delete: false });
  };

  inactivarDashboards = (uid, id) => {
    return firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .get()
      .then((snapShot) => {
        snapShot.docs.forEach((item) => {
          item.id !== id &&
            item.ref.update({
              seleccionado: false,
            });
        });
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  borrarDashboard = () => {
    const { id, uid } = this.state;
    console.log(id);
    return firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .doc(id)
      .delete()
      .then(() => {
        return this.seleccionarDashboardNuevo(uid, id);
      })
      .catch((error) => {
        return false;
      });
  };

  seleccionarDashboardNuevo = (uid, id) => {
    const { dashboards } = this.state;
    const buscar_nuevo_dashboard = dashboards.filter((item) => item.id !== id);
    return firestore
      .collection("usuarios")
      .doc(uid)
      .collection("dashboards")
      .doc(buscar_nuevo_dashboard[0].id)
      .update({
        seleccionado: true,
      })
      .then(() => {
        setLocalStorage("id_dashboard", buscar_nuevo_dashboard[0].id);
        setLocalStorage(
          "widgets",
          buscar_nuevo_dashboard[0].widgets
            ? JSON.stringify(buscar_nuevo_dashboard[0].widgets)
            : []
        );
        setLocalStorage(
          "filtros",
          buscar_nuevo_dashboard[0].filtros
            ? JSON.stringify(buscar_nuevo_dashboard[0].filtros)
            : []
        );
        setLocalStorage(
          "rango_fechas",
          buscar_nuevo_dashboard[0].rango_fechas
            ? JSON.stringify(buscar_nuevo_dashboard[0].rango_fechas)
            : []
        );
        return this.setState({
          dashboards: buscar_nuevo_dashboard,
          id: buscar_nuevo_dashboard[0].id,
        });
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  encontrarDashboard = (id) => {
    const { dashboards } = this.state;
    const encontrar_dashboard = dashboards.filter((item) => item.id === id);
    return encontrar_dashboard.length > 0 ? encontrar_dashboard[0].nombre : "";
  };

  guardarVistoAlerta = async (respuesta) => {
    return firestore
      .collection("notificaciones")
      .doc(respuesta.id_notificacion)
      .update({
        visto: true,
      })
      .then(() => {
        return this.props.history.push(
          `/app/notificaciones/inbox/${respuesta.id}`
        );
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  render() {
    const { drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";
    const {
      dashboards,
      id,
      modo_edicion_dashboard,
      modo_edicion_respuestas,
      open,
      open_confirm_delete,
      errors,
      width,
    } = this.state;

    let path = this.props.location.pathname.split("/")[3];
    const data = { navigationStyle, drawerStyle, path };

    return (
      <AppBar
        className={`app-main-header topbar_fix ${
          navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
            ? "app-main-header-top"
            : ""
        }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {this.showMenuIcon(data)}
          {path !== "dashboard" ? (
            <Link
              className="app-logo mr-2 d-sm-block"
              to="/app/preferencias/preferencia"
            >
              <img
                className="app_logo_fixed"
                src={require("assets/images/logo.png")}
                alt="App Cemtrik"
                title="App Cemtrik"
              />
            </Link>
          ) : (
            ""
          )}
          <Dialog open={open} onClose={this.handleRequestClose}>
            <DialogTitle>Crear Tablero</DialogTitle>
            <DialogContent>
              <form onSubmit={this.actionDocumento} ref="tablerosForm">
                <FormControl
                  className="w-100 mb-2"
                  error={errors.nombre.length > 0 ? true : false}
                >
                  <TextField
                    error={errors.nombre.length > 0 ? true : false}
                    name="nombre"
                    label="Nombre"
                    value={this.state.nombre}
                    onChange={this.updateInputTableros}
                    onBlur={this.validateForm}
                    margin="normal"
                    fullWidth
                    noValidate
                  />
                  {errors.nombre.length > 0 && (
                    <FormHelperText>{errors.nombre}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  className="w-100 mb-2"
                  error={errors.descripcion.length > 0 ? true : false}
                >
                  <TextField
                    error={errors.descripcion.length > 0 ? true : false}
                    name="descripcion"
                    label="Descripción"
                    multiline
                    value={this.state.descripcion}
                    onChange={this.updateInputTableros}
                    onBlur={this.validateForm}
                    margin="normal"
                    fullWidth
                    noValidate
                  />
                  {errors.descripcion.length > 0 && (
                    <FormHelperText>{errors.descripcion}</FormHelperText>
                  )}
                </FormControl>
                <div className="text-center">
                  <br />
                  <button type="submit" className="btn btn-primary">
                    Crear
                  </button>
                  <button
                    type="reset"
                    className="btn btn_r_color div_return_btn"
                    onClick={() => this.handleRequestClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog
            open={open_confirm_delete}
            onClose={this.handleRequestCloseDeleteConfirm}
          >
            <DialogTitle>
              Borrar dashboard {this.encontrarDashboard(id)}
            </DialogTitle>
            <DialogContent>
              ¿Está seguro que desea borrar el dashboard seleccionado? Una vez
              realizada esta acción se borra toda la información vinculada al
              dashboard.
            </DialogContent>
            <DialogActions>
              <button
                onClick={this.handleRequestCloseDeleteConfirm}
                className="btn btn-primary"
              >
                Cancelar
              </button>
              <button
                onClick={this.borrarDashboard}
                className="btn btn-success"
                autoFocus
              >
                Confirmar
              </button>
            </DialogActions>
          </Dialog>

          <ul className="header-notifications list-inline ml-auto">
            {!modo_edicion_dashboard && width >= 1024 && path === "dashboard" && (
              <li className="list-inline-item">
                <FormControl className="w-100 mb-2" error={false}>
                  <TextField
                    select
                    error={false}
                    name="id"
                    label=""
                    className="select-header"
                    variant="outlined"
                    value={id}
                    onChange={this.updateInput}
                    SelectProps={{}}
                    helperText=""
                    margin="normal"
                    fullWidth
                    noValidate
                  >
                    {dashboards.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.id === "nuevo" ? (
                          <em>{item.nombre}</em>
                        ) : (
                          item.nombre
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </li>
            )}
            {path === "dashboard" && (
              <li className="list-inline-item li-editar-dashboard">
                <button
                  type="button"
                  className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                  onClick={this.activarEdicion}
                >
                  {modo_edicion_dashboard ? "Ver" : "Editar"}
                </button>
              </li>
            )}
            {path === "respuestas" && (
              <li className="list-inline-item li-editar-respuestas">
                <button
                  type="button"
                  className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                  onClick={this.activarEdicionRespuestas}
                >
                  {modo_edicion_respuestas ? "Ver" : "Editar"}
                </button>
              </li>
            )}
            {dashboards.length > 2 &&
              modo_edicion_dashboard &&
              path === "dashboard" && (
                <li className="list-inline-item li-editar-dashboard">
                  <button
                    type="button"
                    className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                    onClick={() => {
                      this.setState({ open_confirm_delete: true });
                    }}
                  >
                    Borrar
                  </button>
                </li>
              )}
            {navigationStyle === VERTICAL_NAVIGATION && (
              <UserInfoHorizontal
                stateInfo={this.state.userInfo}
                onUserInfoSelect={this.onUserInfoSelect.bind(this)}
              />
            )}
          </ul>

          <div className="ellipse-shape"></div>
        </Toolbar>
        {width < 1024 && path === "dashboard" && (
          <div className="col-12 mb-2">
            <FormControl className="w-100 mb-2" error={false}>
              <TextField
                select
                error={false}
                name="id"
                label=""
                className="select-header"
                variant="outlined"
                value={id}
                onChange={this.updateInput}
                SelectProps={{}}
                helperText=""
                margin="normal"
                fullWidth
                noValidate
              >
                {dashboards.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.id === "nuevo" ? <em>{item.nombre}</em> : item.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        )}
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
  } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, switchLanguage, userSignOut })(
    Header
  )
);
