import {auth} from '../firebase/firebase';

const validateForms = (states) => {
  //se traen las reglas y el estado errors para los mensajes de errores
  let rules = states.rules;
  let errors = states.errors;
  let validForm = true;
  //se recorren las reglas del formulario para validar cada tipo de campo
  rules.forEach((rule) => {
    //si el campo es requerido se debe validar que no esté vacío el campo
    if(rule.required) {
      //se compara dinámicamente con el valor actual del estado (del campo)
      if(states[rule.field] === ""){
        //console.log(rule);
        errors[rule.field] = [rule.label]+" es requerido";
        validForm = false;
      } else {
        errors[rule.field] = "";
        //validForm = true;
      }
    }
  });

  return [
    errors,
    validForm
  ];
}

/**
** Método global para formatear las fechas en el app
** se le pasa la fecha sin formatera como string y el formato al que se desea convertir
** @params (fecha a formatear en formato string, formato)
**/
const setDateFormat = (fecha, formato) => {
    //fecha puede ir null para formatear la fecha actual
     let fecha_sin_formatear = new Date(fecha);
     //si se le pasa formato procesa la fecha
     //sino se le pasa formato retorna el objeto de la fecha que se pasa en el método
     if(formato === "YYYY-MM-DD") {
       return fecha_sin_formatear.getFullYear()+ "-" + ('0' + (fecha_sin_formatear.getMonth()+1)).slice(-2) + "-" + ('0' + fecha_sin_formatear.getDate()).slice(-2);
     } else if(formato === "YYYY-MM-DD hh:mm:ss") {
       return fecha_sin_formatear.getFullYear()+ "-" + ('0' + (fecha_sin_formatear.getMonth()+1)).slice(-2) + "-" + ('0' + fecha_sin_formatear.getDate()).slice(-2) + " " + ('0' + fecha_sin_formatear.getHours()).slice(-2) +":"+('0' + fecha_sin_formatear.getMinutes()).slice(-2)+":"+('0' + fecha_sin_formatear.getSeconds()).slice(-2);
     } else if(formato === "hh:mm:ss") {
        return !isNaN(fecha_sin_formatear) ? ('0' + (fecha_sin_formatear.getHours()+1)).slice(-2) +":"+('0' + (fecha_sin_formatear.getMinutes()+1)).slice(-2)+":"+('0' + (fecha_sin_formatear.getSeconds()+1)).slice(-2) : fecha;
     } else {
       return fecha_sin_formatear;
     }
}

const newAttributes = () => {
  //se obtiene la sesión del usuario logueado
  return auth.onAuthStateChanged((user) => {
    //Si etá logueado
    if(user){

      return {
        uid: user.uid,
        correo: user.email,
        nombres: user.displayName
      }

    } else {
      return false;
    }
  });
}

const testStates = (states) => {
  console.log(states);
}

const setLocalStorage = (param, value) => localStorage.setItem(param, value);

const getLocalStorage = (param) => localStorage.getItem(param);

const cleanString = (cadena) => {
  if (cadena === undefined) {
    return "";
  }
  var b = cadena.split(/\s+/g);
  var c = b.map(function (item) {
    return item.replace(/\s+/g, "");
  });
  return c.join(" ").trim().toUpperCase();
}

const formatNumberDecimals = (numero, decimales) => {
  return parseFloat(numero).toFixed(decimales);
}

const formatNumberThousands = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export {
  validateForms,
  setDateFormat,
  newAttributes,
  testStates,
  setLocalStorage,
  getLocalStorage,
  cleanString,
  formatNumberDecimals,
  formatNumberThousands
}
