import React from 'react';

const Footer = () => {
    return (
      <footer className="app-footer estilos_footer">
        <span className="d-inline-block">2020 © todos los derechos reservados. Ver términos y condiciones.</span>
        <span className="d-inline-block estilo_creado_con">Creado con <i className="material-icons d-inline-block corazon_footer">favorite&nbsp;</i><div className="d-inline-block estilo_creado_con">por&nbsp;</div><div className="d-inline-block estilos_footer">Valor Percibido</div></span>
      </footer>
    );
  }
;

export default Footer;
