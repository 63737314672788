import firebase from 'firebase';
//se valida si la app está corriendo en modo pruebas o producción
import configuracion from '../config';

// Config app cemtrik
const config = {
    apiKey: configuracion.api_key_firebase_app,
    authDomain: configuracion.auth_domain_app,
    projectId: configuracion.projectId_app,
    storageBucket: configuracion.storageBucket_app
};
//Inicio de aplicación App cemtrik
const app_cemtrik = firebase.initializeApp(config, configuracion.projectId_app);
const auth = app_cemtrik.auth();


const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const firestore = app_cemtrik.firestore();
const functions = app_cemtrik.functions();
//firestore.settings({timestampsInSnapshots:true});

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firestore,
  firebase,
  functions
};
