import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import {auth} from '../../firebase/firebase';

class UserInfoPopup extends React.Component {

  /**
  ** Método para logout
  **/
  signOut = () => {
    this.deleteLocalStorage();
    auth.signOut();
  };

  deleteLocalStorage = () => {
    localStorage.removeItem("rango_fechas");
    localStorage.removeItem("filtros");
    localStorage.removeItem("id_dashboard");
    localStorage.removeItem("widgets");
    localStorage.removeItem("rango_fechas_respuestas");
    localStorage.removeItem("user_id");
    localStorage.removeItem("id_marca");
    localStorage.removeItem("nombre_marca");
    localStorage.removeItem("filtros_respuestas");
    localStorage.removeItem("currentMail");
    return true;
  }

  render() {
    return (
      <div>
        <div className="user-profile">
          <img className="user-avatar border-0 size-30 rounded-circle"
               src={this.props.src}
               alt={this.props.nombres}/>
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{this.props.nombres}</h4>
            <small>Administrador</small>
          </div>
        </div>
        {/*<span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          Perfil
        </span>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.setting"/>
        </span>*/}
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          //console.log("Try to logoput");
          this.signOut()
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          Salir
        </span>
      </div>
    );
  }
}

export default connect(null, {userSignOut})(UserInfoPopup);
