module.exports = {
  api_key_google_maps: "AIzaSyBqO9Oymz9dPtDuvzSsOitm6HlGS38cZm8",
  api_key_firebase_app: "AIzaSyBKGaPFRkAtldC9XMe39vBx7I_AVQSpGjA",
  auth_domain_app: "admin-dev-cemtrik.firebaseapp.com",
  projectId_app: "admin-dev-cemtrik",
  storageBucket_app: "admin-dev-cemtrik.appspot.com",
  messagingSenderId: "57849133671",
  appId: "1:57849133671:web:75dc21e7c53235275532c2",
  measurementId: "G-1BKHD0TSXD",
  api_key_firebase_worldDB: "AIzaSyDn06RmDXwmOe5ndYLRCLcdFytZPOsNufo",
  auth_domain_worldDB: "worlddb-cemtrik.firebaseapp.com",
  projectId_worldDB: "worlddb-cemtrik",
  format_date: "YYYY-MM-DD",
  format_date_time: "YYYY-MM-DD hh:mm:ss",
  format_time: "hh:mm:ss",
  url_api_admin_dev: "http://localhost:5001/admin-dev-cemtrik/us-central1",
  url_api_admin_prod:
    "https://us-central1-admin-dev-cemtrik.cloudfunctions.net",
  api_key_admin: "2qVIRedzkFK26NT7OP4i",
  cube_js_token:
    "https://round-marmoset.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1",
  privateEndPoint: "https://private-api-qkpvp7r.uc.gateway.dev",
};
