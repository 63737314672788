import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {userSignOut} from 'actions/Auth';
import IconButton from '@material-ui/core/IconButton';
import AvatarNoUser from '../../assets/images/avatar_no_user.png';
import UserInfoPopup from 'components/UserInfoHorizontal/UserInfoPopup';
import {auth} from '../../firebase/firebase';

class UserInfoHorizontal extends React.Component {

  state = {
    nombre:'',
    photoURL:'',
    telefono: '',
    anchorEl: null,
    open: false,
  };

  componentDidMount() {
      auth.onAuthStateChanged((user) => {
        if(user) {
          this.userAuth(user);
        } else {
          //console.log("El usuario no se encuentra registrado")
          this.setState({
           nombre: "",
           photoURL: AvatarNoUser
         });
        }
      });
  }

  userAuth(user){
        if((user.displayName).length<17){
          this.setState({
            nombre: user.displayName,
            photoURL:user.photoURL
          })
        }else{
          let arr = user.displayName.split(' ');
            let inicial=arr[1].slice(0, (-(arr[1].length))+1);
            this.setState({
              nombre: arr[0]+' '+inicial+'.',
              photoURL: user.photoURL
            })
         }
  }



  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <li className="list-inline-item user-nav">
        <Dropdown
          className="quick-menu"
          isOpen={this.props.stateInfo}
          toggle={this.props.onUserInfoSelect}>

          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown">
            <IconButton className="icon-btn size-30">
              <Avatar
                alt={this.state.nombre}
                src={this.state.photoURL ? this.state.photoURL : 'https://ya-webdesign.com/images/avatar-png-1.png'}
                className="size-30"
              />
            </IconButton>
          </DropdownToggle>

          <DropdownMenu right>
            <UserInfoPopup
            nombres={this.state.nombre}
            src={this.state.photoURL ? this.state.photoURL : 'https://via.placeholder.com/150x150'}

            />
          </DropdownMenu>
        </Dropdown>
      </li>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};
export default connect(mapStateToProps, {userSignOut})(UserInfoHorizontal);
